export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILED = 'AUTH_FAILED';
export const INIT_LOGOUT = 'INIT_LOGOUT';
export const LOGOUT = 'LOGOUT';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const VIEW_RESPONSIVE = 'VIEW_RESPONSIVE';

