import React, { useEffect } from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

const FrontPage = React.lazy(() => import('./containers/FrontPage'));
const Privacy = React.lazy(() => import('./HTML/privacy'));


const App = () => {
    let loading = <div/>;
  return (
    <>
    {
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route path="/privacy" name="privacy" render={props => <Privacy {...props}/>} />
            <Route path="/" name="Home" render={props => <FrontPage {...props}/>} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    }
    </>
  );

}

export default App;
