export const updateObj = (oldObj, updatedProperties) =>{
    return{
        ...oldObj,
        ...updatedProperties
    }
};

export const validateEmail = (emailAdress) =>
{
  // let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  let regexEmail = /\S+@\S+\.\S+/;
  if (emailAdress.match(regexEmail)) {
    return true; 
  } else {
    return false; 
  }
}